import React from 'react'
import {Link, useParams} from 'react-router-dom'
import Error from '../../components/Error'
import Success from '../../components/Success'
import NotFound from '../../components/NotFound'

const Successfuly = () => {
  const {slug} = useParams()

  return <div className='d-flex justify-content-start align-items-center flex-column'>{slug == 'false' ? <Error /> : <Success />}</div>
}

export default Successfuly
