export const RegisterApi = (instance: any) => ({
    async postData(body: any) {
        const { data } = await instance.post(`/register`, body);
        return data;
    },
    async checkUser(id: any) {
        const { data } = await instance.get(`/user_check/${id}`);
        return data;
    },
    async apply(body: any) {
        const { data } = await instance.post(`/apply`, body);
        return data;
    },
    async getInstallments(body: any) {
        const { data } = await instance.post(`/installments`, body);
        return data;
    },
    async startInstallments(id: any, body: any) {
        const { data } = await instance.post(`/payment/${id}/3d_start`, body);
        return data;
    }
});