import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

const Success = () => {
  useEffect(() => {
    localStorage.removeItem('ecs-apply')
    localStorage.removeItem('ecs-token')
  }, [])

  return (
    <div className='col-md-4 mt-5'>
      <div className='card shadow p-5' style={{backgroundColor: '#29386F'}}>
        <div className='mb-4 text-center'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='text-success bi bi-check-circle'
            width='75'
            height='75'
            fill='currentColor'
            //   class="bi bi-check-circle"
            viewBox='0 0 16 16'
          >
            <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
            <path d='M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z' />
          </svg>
        </div>
        <div className='text-center'>
          <h1 className='text-white'>Teşekkür Ederiz!</h1>
          <p className='text-white'>
            2023-2024 Bahar Dönemi için kayıt işleminiz tamamlanmıştır. <br /> Akademik Takvim eğitim ile ilgili ayrıca bilgilendiriliyor olacaksınız. Başarılar.
          </p>
          <Link to={'/'} className='btn btn-outline-success mt-3'>
            Forma Dön
          </Link>
          <Link to={'https://europecodingschool.com'} className='mt-4 text-white cursor-pointer text-decoration-underline nav-link'>
            EuropeCodingSchool
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Success
