import React, {useEffect, useState} from 'react'
import ViewPages from './components/ViewPages'
import {useNavigate, useParams} from 'react-router-dom'
import Payment from './components/Payment'
import {Api} from '../../services/api'
import {IEduProgram, IEduTerms} from '../../helpers/models/EducationInfo.model'
import {IUser} from '../../helpers/models/User.model'
import SelectProgram from './components/SelectProgram'
import {IApply} from '../../helpers/models/ApplyRes.model'

const ApplicationForm = () => {
  const nav = useNavigate()
  const {id, page} = useParams()
  const [currentStep, setCurrentStep] = useState(Number(page))
  const [eduPrograms, setEduPrograms] = useState<IEduProgram[]>([])
  const [eduTerms, setEduTerms] = useState<IEduTerms[]>([])
  const [user, setUser] = useState<IUser>({} as IUser)
  const [apply, setApply] = useState<IApply>({} as IApply)
  const [confettiRun, setConfettiRun] = useState(false)

  const setCode = (e: any) => {
    if (e?.target?.value?.length > 5) {
      setConfettiRun(true)
    } else {
      setConfettiRun(false)
    }
  }
  useEffect(() => {
    const forPars = localStorage.getItem('ecs-apply') || ''

    let applyData: IApply
    try {
      applyData = JSON.parse(forPars)
    } catch (error) {
      console.error('Error parsing JSON:', error)
      // Handle the error as needed, e.g., set applyData to a default value or show an error message
    }
    Api()
      .register.checkUser(id)
      .then((res) => {
        if (res?.success) {
          localStorage.setItem('ecs-token', res?.data?.token)
          setUser(res?.data)
          if (applyData) if (res?.data?.id === applyData?.user_id) setApply(applyData)
        } else {
          nav('/error', {replace: true})
        }
      })
    Api()
      .education.getProgram()
      .then((res) => {
        if (res?.success) {
          setEduPrograms(res?.data)
        }
      })
    Api()
      .education.getTerms()
      .then((res) => {
        if (res?.success) {
          setEduTerms(res?.data)
        }
      })
  }, [])

  return (
    <div className=''>
      <div className='steps'>
        <div className={`step ${currentStep >= 1 && 'active'} `}>
          <div className='step-progress'>
            <span className='step-progress-start'></span>
            <span className='step-progress-end'></span>
            <span className='step-number'>1</span>
          </div>
          <div className='step-label text-white'>Öğrenci Bilgileri</div>
        </div>
        <div className={`step ${currentStep >= 2 && 'active'} `}>
          <div className='step-progress'>
            <span className='step-progress-start'></span>
            <span className='step-progress-end'></span>
            <span className='step-number'>2</span>
          </div>
          <div className='step-label text-white'>Akademik Program</div>
        </div>
        <div className={`step ${currentStep >= 3 && 'active'} `}>
          <div className='step-progress'>
            <span className='step-progress-start'></span>
            <span className='step-progress-end'></span>
            <span className='step-number'>3</span>
          </div>
          <div className='step-label text-white'>Eğitim Özeti</div>
        </div>
        <div className={`step ${currentStep >= 4 && 'active'} `}>
          <div className='step-progress'>
            <span className='step-progress-start'></span>
            <span className='step-progress-end'></span>
            <span className='step-number'>4</span>
          </div>
          <div className='step-label text-white'>Ödeme</div>
        </div>
      </div>
      {currentStep === 1 && <ViewPages user={user} currentStep={currentStep} setCurrentStep={setCurrentStep} />}
      {currentStep === 2 && <SelectProgram eduPrograms={eduPrograms} eduTerms={eduTerms} setCurrentStep={setCurrentStep} setApply={setApply} apply={apply} />}
      {currentStep === 3 && <ViewPages user={user} currentStep={currentStep} setCode={setCode} setCurrentStep={setCurrentStep} apply={apply} />}
      {currentStep === 4 && <Payment setCurrentStep={setCurrentStep} apply={apply} />}
    </div>
  )
}

export default ApplicationForm
