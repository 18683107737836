import React from 'react'
import {Link} from 'react-router-dom'

const Error = () => {
  return (
    <div className='col-md-4 mt-5'>
      <div className='card shadow p-5' style={{backgroundColor: '#29386F'}}>
        <div className='mb-4 text-center'>
          <svg xmlns='http://www.w3.org/2000/svg' className='text-warning bi bi-exclamation-circle' width='75' height='75' fill='#dc3545' viewBox='0 0 16 16'>
            <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
            <path d='M8 4.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 1 .5-.5zM8 10a.5.5 0 0 1 .5.5v.5a.5.5 0 0 1-1 0v-.5a.5.5 0 0 1 .5-.5z' />
          </svg>
        </div>
        <div className='text-center'>
          <h1 className='text-white'>Hata Oluştu!</h1>
          <p className='text-white'>We've send the link to your inbox. </p>
          <Link to={'/'} className='btn btn-outline-primary'>
            Forma Dön
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Error
