import React, {FC} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import App from '../App'
import RegisterForm from '../pages/register-form/RegisterForm'
import ApplicationForm from '../pages/application-form/ApplicationForm'
import Successfuly from '../pages/successfuly/successfuly'
import NotFound from '../components/NotFound'

const {PUBLIC_URL} = process.env

const AppRoutes: FC<{children?: React.ReactNode}> = () => {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='/error' element={<NotFound />} />
          <Route path='/register-form' element={<RegisterForm />} />
          <Route path='/application-form/:id/:page' element={<ApplicationForm />} />
          <Route path='/successfuly' element={<Successfuly />} />
          <Route index path='*' element={<Navigate to='/register-form' />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
