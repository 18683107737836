import axios from 'axios'
import { RegisterApi } from './register';
import { UniApi } from './university';
import { EducationApi } from './education-info';



export const Api = () => {
    const token = localStorage.getItem('ecs-token') || ''
    const apitoken = 'Ex7KldZAYd3LuhoEqTKKDhlWhrhGBZovw7wRjt94fA6z4L4ElRXOPzSyDLPrzD';
    const instance = axios.create({
        baseURL: 'https://api.europecodingschool.com/api',
        headers: {
            Authorization: `Bearer ${token}`,
            apitoken: apitoken
        },
    })

    return {
        register: RegisterApi(instance),
        uni: UniApi(instance),
        education: EducationApi(instance)
    }
}
