import React, {useEffect, useState} from 'react'

import * as Yup from 'yup'
import {useFormik} from 'formik'
import {PatternFormat} from 'react-number-format'
import {Api} from '../../services/api'
import Swal from 'sweetalert2'
import {IBranches, IUniversity} from '../../helpers/models/University.model'

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .required('Bu alan boş olamaz!')
    .matches(/^[a-zA-ZğüşıöçĞÜŞİÖÇ]+\s[a-zA-ZğüşıöçĞÜŞİÖÇ]+$/, 'Ad ve soyadı arasında boşluk olmalıdır.')
    .min(3, 'En az 3 karakter olmalı'),
  university_id: Yup.string(),
  branch_id: Yup.string(),
  class: Yup.string(),
  phone: Yup.string()
    .matches(/^\+90\s5\d{2}\s\d{3}\s\d{2}\s\d{2}$/, 'Geçerli bir telefon numarası giriniz.')
    .required('Lütfen belirtilen formatta bir telefon numarası gir.'),
})

const RegisterForm = () => {
  const [uni, setUni] = useState<IUniversity[]>([])
  const [branches, setBranches] = useState<IBranches[]>([])
  const initialValues = {
    name: '',
    phone: '',
    university_id: '',
    class: '',
    branch_id: '',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: SignupSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      const body = {
        ...values,
        phone: values.phone?.replace(/\s/g, '').slice(3),
        branch_id: Number(values?.branch_id),
        university_id: Number(values?.university_id),
      }
      Api()
        .register.postData(body)
        .then((res) => {
          if (res?.success) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              // iconColor: '#dc3545',
              title: 'Teşekkür ederiz!',
              text: res?.message,
              showConfirmButton: true,
              // timer: 2500,
            })
            resetForm()
          } else {
            Swal.fire({
              icon: 'error',
              title: `#${res.code}`,
              text: res?.message,
            })
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: `#${err?.response.message}`,
            text: err?.response.message,
          })
        })
        .finally(() => setSubmitting(false))
    },
  })

  useEffect(() => {
    Api()
      .uni.getUni()
      .then((res) => {
        if (res?.success) setUni(res?.data)
      })
    Api()
      .uni.getBranch()
      .then((res) => {
        if (res?.success) setBranches(res?.data)
      })
  }, [])
  return (
    <main className=''>
      <form onSubmit={formik.handleSubmit} className='form-signin text-center h-100 '>
        <div className='w-100 d-flex flex-column align-items-center mt-5'>
          <div style={{width: '300px'}} className='mb-3 text-start fs-5'>
            <label htmlFor='exampleInputAd' className='form-label text-white'>
              Ad Soyad
            </label>
            <input type='text' {...formik.getFieldProps('name')} name='name' className='form-control form-control-lg' id='exampleInputAd' aria-describedby='emailHelp' />
            {formik.touched.name && formik.errors.name && <span className='text-primary fs-sm'>{formik.errors.name}</span>}
          </div>
          <div style={{width: '300px'}} className='mb-3 text-start'>
            <label htmlFor='exampleInputUni' className='form-label text-white fs-5'>
              Üniversite
            </label>
            <select className='form-select form-select-lg mb-3' {...formik.getFieldProps('university_id')} name='university_id'>
              <option value=''>Seç</option>
              {uni?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item?.university_name}
                </option>
              ))}
            </select>
            {formik.touched.university_id && formik.errors.university_id && <span className='text-primary fs-sm'>{formik.errors.university_id}</span>}
          </div>
          <div style={{width: '300px'}} className='mb-3 text-start'>
            <label htmlFor='exampleInputBolum' className='form-label text-white fs-5'>
              Bölüm
            </label>
            <select className='form-select form-select-lg mb-3' {...formik.getFieldProps('branch_id')} name='branch_id'>
              <option value=''>Seç</option>
              {branches?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item?.branch_name}
                </option>
              ))}
            </select>
            {formik.touched.branch_id && formik.errors.branch_id && <span className='text-primary fs-sm'>{formik.errors.branch_id}</span>}
          </div>
          <div style={{width: '300px'}} className='mb-3 text-start'>
            <label htmlFor='exampleInputSinif' className='form-label text-white fs-5'>
              Sınıf
            </label>
            <select className='form-select form-select-lg mb-3' {...formik.getFieldProps('class')} name='class'>
              <option value=''>Seç</option>
              <option value='Hazırlık'>Hazırlık</option>
              <option value='1. Sınıf'>1. Sınıf</option>
              <option value='2. Sınıf'>2. Sınıf</option>
              <option value='3. Sınıf'>3. Sınıf</option>
              <option value='4. Sınıf'>4. Sınıf</option>
              <option value='5. Sınıf'>5. Sınıf</option>
              <option value='6. Sınıf'>6. Sınıf</option>
              <option value='7. Sınıf'>7. Sınıf</option>
              <option value='Mezun'>Mezun</option>
            </select>
            {formik.touched.class && formik.errors.class && <span className='text-primary fs-sm'>{formik.errors.class}</span>}
          </div>
          <div style={{width: '300px'}} className='mb-3 text-start'>
            <label htmlFor='exampleInputSinif' className='form-label text-white fs-5'>
              Telefon Numara
            </label>
            <PatternFormat format={`+${90} ### ### ## ##`} {...formik.getFieldProps('phone')} name='phone' className='form-control form-control-lg' id='exampleInputSinif' placeholder='+90 5** *** ** **' aria-describedby='emailHelp' />
            {formik.touched.phone && formik.errors.phone && <span className='text-primary fs-sm'>{formik.errors.phone}</span>}
          </div>
        </div>
        <button disabled={formik.isSubmitting || !formik.isValid} className='btn btn-lg btn-primary btn-block rounded rounded-4 mt-3 px-5'>
          Hemen Başvur
        </button>

        <p className='mt-5 mb-3 text-center text-light' style={{fontSize: '10px', color: '#4b8ac3'}}>
          © 2023-2024
        </p>
      </form>
    </main>
  )
}

export default RegisterForm
