//https://eucodingpay.ecodation.com/api/
import axios from 'axios'
import { PayApi } from './payment';




export const ApiPay = () => {
    const token = localStorage.getItem('ecs-token') || ''
    const apitoken = 'Ex7KldZAYd3LuhoEqTKKDhlWhrhGBZovw7wRjt94fA6z4L4ElRXOPzSyDLPrzD';
    const instance = axios.create({
        baseURL: 'https://eucodingpay.ecodation.com/api',
        headers: {
            Authorization: `Bearer ${token}`,
            apitoken: apitoken
        },
    })

    return {
        pay: PayApi(instance)
    }
}
