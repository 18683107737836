import React, {FC, useEffect, useState} from 'react'
import {IEduProgram, IEduTerms} from '../../../helpers/models/EducationInfo.model'
import {Api} from '../../../services/api'
import Swal from 'sweetalert2'
import {IApply} from '../../../helpers/models/ApplyRes.model'
import ConfettiExplosion from 'react-confetti-explosion'

type Props = {
  eduPrograms: IEduProgram[]
  eduTerms: IEduTerms[]
  setCurrentStep: any
  setApply: any
  apply: IApply
}

const SelectProgram: FC<Props> = ({eduPrograms, eduTerms, setCurrentStep, apply, setApply}) => {
  const [hasScholarship, setHasScholarship] = useState('')
  const [isExploding, setIsExploding] = useState(false)
  const [selectedProgram, setselectedProgram] = useState<IEduProgram>()
  const [selectedProgramId, setSelectedProgramId] = useState(apply?.edu_program?.id ? apply?.edu_program?.id : '')
  const [selectedTermId, setSelectedTermId] = useState(apply?.edu_term?.id ? apply?.edu_term?.id : '')
  const [code, setCode] = useState('')

  useEffect(() => {
    if (selectedProgramId) setselectedProgram(eduPrograms?.filter((item) => item?.id === Number(selectedProgramId))[0])
  }, [selectedProgramId])

  const submit = () => {
    const body = {
      edu_program_id: Number(selectedProgramId),
      edu_term_id: Number(selectedTermId),
      discount_code: hasScholarship == '1' ? code : '',
    }
    Api()
      .register.apply(body)
      .then((res) => {
        if (res?.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            // iconColor: '#dc3545',
            text: res?.message,
            // showConfirmButton: false,
            // timer: 2500,
          })
          localStorage.setItem('ecs-apply', JSON.stringify(res?.data))
          setApply(res?.data)
          setIsExploding(true)
          setTimeout(() => {
            setCurrentStep((prev: number) => prev + 1)
          }, 3000)
        } else {
          Swal.fire({
            icon: 'error',
            title: `#${res.code}`,
            text: res?.message,
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: `#${err?.response.message}`,
          text: err?.response.message,
        })
      })
  }
  return (
    <div>
      {isExploding && hasScholarship == '1' && (
        <div className='w-100 d-flex justify-content-center align-items-center'>
          <ConfettiExplosion particleCount={390} force={0.8} duration={5000} width={1500} height={1000} zIndex={10000} />
        </div>
      )}
      <div className='w-100 d-flex flex-column align-items-center mt-5'>
        <div style={{width: '300px'}} className='mb-3 text-start fs-5'>
          <label className='form-label text-white'>Akademik Program Seç</label>
          <select
            onChange={(e) => {
              setSelectedProgramId(e.target?.value)
            }}
            defaultValue={selectedProgramId}
            className='form-select form-select-lg mb-3'
            aria-label='.form-select-lg example'
          >
            <option disabled value={''}>
              Seç
            </option>
            {eduPrograms?.map((item) => (
              <option key={item?.id} value={item?.id}>
                {item?.edu_program_name}
              </option>
            ))}
          </select>
        </div>
        <div style={{width: '300px'}} className='mb-3 text-start fs-5'>
          <label className='form-label text-white'>Kayıt dönemi Seç</label>
          <select
            onChange={(e) => {
              setSelectedTermId(e.target?.value)
            }}
            defaultValue={selectedTermId}
            className='form-select form-select-lg mb-3'
            aria-label='.form-select-lg example'
          >
            <option disabled value={''}>
              Seç
            </option>
            {eduTerms?.map((item) => (
              <option disabled={item?.specs == 32} key={item?.id} value={item?.id}>
                {item?.edu_term_title}
              </option>
            ))}
          </select>
        </div>
        {selectedProgram && selectedTermId && (
          <div style={{width: '300px'}} className='mb-3 text-start'>
            <label htmlFor='exampleInputSinif' className='form-label text-white fs-5'>
              Eğitim Kayıt Ücreti
            </label>
            <div className='form-control form-control-lg disabled'>
              <span className=''> {selectedProgram?.edu_program_price.toLocaleString('tr-TR')} ₺ </span>
            </div>
          </div>
        )}

        <div style={{width: '300px'}} className='mb-3 text-start fs-5'>
          <label className='form-label text-white'>Eğitim bursun var mı?</label>
          <select className='form-select form-select-lg mb-3' onChange={(e) => setHasScholarship(e?.target?.value)}>
            <option value=''>Seç</option>
            <option value='1'>Evet</option>
            <option value='0'>Hayır</option>
          </select>
        </div>
        {hasScholarship == '1' && (
          <div style={{width: '300px'}} className='mb-3 text-start fs-5'>
            <label htmlFor='exampleInputAd' className='form-label text-white'>
              Kod
            </label>
            <input type='text' className='form-control form-control-lg' id='exampleInputAd' onChange={(e) => setCode(e.target?.value)} aria-describedby='emailHelp' />
          </div>
        )}
      </div>
      <div className='position-relative bottom-0 d-flex justify-content-center w-100 pb-5'>
        <div style={{width: '300px'}} className='d-flex justify-content-around '>
          <div onClick={() => setCurrentStep((prev: number) => prev - 1)} className={`btn btn-lg btn-outline-light`}>
            Geri
          </div>
          <div onClick={submit} className='btn btn-lg btn-primary'>
            Sonraki
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectProgram
