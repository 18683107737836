import React from "react";
import { toAbsoluteUrl } from "../helpers";

const Header = () => {
  return (
    <div className="text-center mb-4">
      <img
        className="mb-4"
        src={toAbsoluteUrl("/media/logo.webp")}
        alt=""
        width={300}
        height={120}
      />
    </div>
  );
};

export default Header;
