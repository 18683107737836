import React, {FC, useState} from 'react'
import {IUser} from '../../../helpers/models/User.model'
import {IApply} from '../../../helpers/models/ApplyRes.model'

type Props = {
  user: IUser
  setCode?: any
  currentStep: number
  setCurrentStep: any
  apply?: IApply
}

const ViewPages: FC<Props> = ({setCode, currentStep, user, setCurrentStep, apply}) => {
  const [hasScholarship, setHasScholarship] = useState('')
  return (
    <div>
      <div className='w-100 d-flex flex-column align-items-center mt-5'>
        {currentStep === 3 && apply?.scholarship && (
          <div style={{width: '300px'}} className='alert alert-success' role='alert'>
            <>
              <span> Eğitim Kayıt Ücreti: </span>
              <span className='text-decoration-line-through' style={{fontSize: '16px'}}>
                {apply?.edu_program?.edu_program_price.toLocaleString('tr-TR')} ₺
              </span>
              <span className=''> {(apply?.edu_program?.edu_program_price - apply?.scholarship?.discount).toLocaleString('tr-TR')} ₺</span>
            </>
          </div>
        )}
        <div style={{width: '300px'}} className='mb-3 text-start fs-5'>
          <label className='form-label text-white'>Ad Soyad</label>
          <div className='form-control form-control-lg disabled'>
            {user?.firstname} {user?.lastname}
          </div>
        </div>
        <div style={{width: '300px'}} className='mb-3 text-start'>
          <label htmlFor='exampleInputUni' className='form-label text-white fs-5'>
            Üniversite
          </label>
          <div className='form-control form-control-lg disabled'>{user?.university?.university_name}</div>
        </div>
        <div style={{width: '300px'}} className='mb-3 text-start'>
          <label htmlFor='exampleInputBolum' className='form-label text-white fs-5'>
            Bölüm
          </label>
          <div className='form-control form-control-lg disabled'>{user?.branch?.branch_name}</div>
        </div>
        <div style={{width: '300px'}} className='mb-3 text-start'>
          <label htmlFor='exampleInputSinif' className='form-label text-white fs-5'>
            Sınıf
          </label>
          <div className='form-control form-control-lg disabled'>{user?.class_name}</div>
        </div>
        {currentStep === 3 && (
          <>
            <div style={{width: '300px'}} className='mb-3 text-start'>
              <label htmlFor='exampleInputSinif' className='form-label text-white fs-5'>
                Akademik Program
              </label>
              <div className='form-control form-control-lg disabled'>{apply?.edu_program?.edu_program_name}</div>
            </div>
            <div style={{width: '300px'}} className='mb-3 text-start'>
              <label htmlFor='exampleInputSinif' className='form-label text-white fs-5'>
                Kayıt Dönemi
              </label>
              <div className='form-control form-control-lg disabled'>{apply?.edu_term?.edu_term_title}</div>
            </div>
            <div style={{width: '300px'}} className='mb-3 text-start'>
              <label htmlFor='exampleInputSinif' className='form-label text-white fs-5'>
                Eğitim Kayıt Ücreti
              </label>
              <div className='form-control form-control-lg disabled'>
                {apply?.scholarship && apply?.scholarship?.discount < apply?.edu_program?.edu_program_price ? (
                  <>
                    <span className='text-decoration-line-through' style={{fontSize: '16px'}}>
                      {(apply?.edu_program?.edu_program_price).toLocaleString('tr-TR')} ₺
                    </span>
                    <span className=''> {(apply?.edu_program?.edu_program_price - apply?.scholarship?.discount).toLocaleString('tr-TR')} ₺</span>
                  </>
                ) : apply?.scholarship && apply?.scholarship?.discount > apply?.edu_program?.edu_program_price ? (
                  <span className=''> 0 ₺ </span>
                ) : (
                  <span className=''> {apply?.edu_program?.edu_program_price.toLocaleString('tr-TR')} ₺ </span>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <div className='position-relative bottom-0 d-flex justify-content-center w-100 pb-5'>
        <div style={{width: '300px'}} className='d-flex justify-content-around '>
          {currentStep !== 1 && (
            <div onClick={() => setCurrentStep((prev: number) => prev - 1)} className={`btn btn-lg btn-outline-light d-${currentStep == 1 ? 'none' : 'block'}`}>
              Geri
            </div>
          )}
          <div onClick={() => setCurrentStep((prev: number) => prev + 1)} className='btn btn-lg btn-primary'>
            Sonraki
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewPages
