import React, {FC, useEffect, useState} from 'react'
import {PatternFormat} from 'react-number-format'
import {toAbsoluteUrl} from '../../../helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import Swal from 'sweetalert2'
import {Api} from '../../../services/api'
import {IApply} from '../../../helpers/models/ApplyRes.model'
import {IInstallmentDetail} from '../../../helpers/models/Payment.model'
import {ApiPay} from '../../../services/ApiPay'

type Props = {
  setCurrentStep: any
  apply: IApply
}

const PaymentSchema = Yup.object().shape({
  cardHolder: Yup.string()
    .required('Bu alan boş olamaz!')
    .matches(/^[a-zA-ZğüşıöçĞÜŞİÖÇ]+\s[a-zA-ZğüşıöçĞÜŞİÖÇ]+$/, 'Ad ve soyadı arasında boşluk olmalıdır.')
    .min(3, 'En az 3 karakter olmalı'),
  cardNumber: Yup.string().required('Bu alan boş olamaz!').min(19, 'Lütfen belirtilen formatta gir.'),
  cardExpirationDate: Yup.string().required('Bu alan boş olamaz!').min(5, 'Lütfen belirtilen formatta gir.'),
  installmantId: Yup.string(),
  cardCvc: Yup.string().required('Lütfen belirtilen formatta gir.'),
})

const Payment: FC<Props> = ({setCurrentStep, apply}) => {
  const [loading, setLoading] = useState(false)
  const [totalPrice, setTotalPrice] = useState(apply?.edu_program?.edu_program_price)
  const [installments, setInstalments] = useState<IInstallmentDetail | null>(null)
  const cards = [
    {imgSrc: '/media/payment/visa.svg', imgAlt: 'Visa'},
    {imgSrc: '/media/payment/mastercard.svg', imgAlt: 'MasterCard'},
    // {imgSrc: '/media/payment/troy.svg', imgAlt: 'Troy'},
  ]
  const initialValues = {
    cardHolder: '',
    cardNumber: '',
    cardExpirationDate: '',
    cardCvc: '',
    installmantId: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: PaymentSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      const body = {
        installmentNumber: values?.installmantId ? Number(values?.installmantId) : 1,
        totalInstallmentPrice: totalPrice,
        // "cardNumber": "4603450000000000"
        cardNumber: values?.cardNumber?.replace(/\s+/g, ''),
        cardHolder: values.cardHolder,
        expireMonth: values?.cardExpirationDate?.split('/')[0],
        expireYear: `20${values?.cardExpirationDate?.split('/')[1]}`,
        cvv: values.cardCvc,
      }

      ApiPay()
        .pay.startInstallments(apply?.id, body)
        .then((res) => {
          if (res?.status == 'success') {
            console.log(res)
            // const body1 = {...res, application_id: apply?.id}
            window.location.href = `https://eucodingpay.ecodation.com/api/payment/threed-page/${res?.threeDSHtmlContent}?conversationId=${res?.conversationId}`
          } else {
            Swal.fire({
              icon: 'error',
              title: `#${res.code}`,
              text: res?.message,
            })
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: `#${err?.response.message}`,
            text: err?.response.message,
          })
        })
        .finally(() => setSubmitting(false))
    },
  })

  useEffect(() => {
    if (window?.location?.search) {
      Swal.fire({
        icon: 'error',
        title: `Ödeme Başarısız`,
        text: 'Ödeme alınamadı tekrar deneyiniiz!',
      })
    }
  }, [])

  useEffect(() => {
    if (formik?.values?.installmantId) {
      const price = installments?.installmentPrices?.filter((item) => item?.installmentNumber == Number(formik?.values?.installmantId))
      price && setTotalPrice(price[0].totalPrice)
    } else setTotalPrice(!apply?.scholarship_id ? apply?.edu_program?.edu_program_price : apply?.scholarship?.discount > apply?.edu_program?.edu_program_price ? 0 : apply?.edu_program?.edu_program_price - apply.scholarship?.discount)
  }, [formik?.values?.installmantId, apply])

  const handleChange = (e: any) => {
    formik.handleChange(e)
    let carddNumber = e.target.value
    if (carddNumber.replace(/\s+/g, '')?.length <= 6) setInstalments(null)
    else if (carddNumber.replace(/\s+/g, '')?.slice(0, 6)?.length == 6) {
      const price = !apply?.scholarship_id ? apply?.edu_program?.edu_program_price : apply?.scholarship?.discount > apply?.edu_program?.edu_program_price ? 0 : apply?.edu_program?.edu_program_price - apply.scholarship?.discount
      const body = {
        price: price,
        binNumber: carddNumber.replace(/\s+/g, '')?.slice(0, 6),
        conversationId: apply?.id,
      }
      Api()
        .register.getInstallments(body)
        .then((res) => {
          if (res?.status === 'success' && res?.installmentDetails?.length > 0) {
            setInstalments(res?.installmentDetails[0])
          } else {
            setInstalments(null)
          }
        })
    }
  }

  return (
    <div className='w-100 d-flex flex-column align-items-center mt-5'>
      <div className='d-flex align-items-center flex-wrap '>
        <h5 className='h4 me-3 mb-3 text-white'>
          Toplam Tutar:{' '}
          <span style={{fontSize: '18px'}} className='text-decoration-line-through'>
            {apply?.edu_program?.edu_program_price.toLocaleString('tr-TR')} ₺
          </span>
          <span className=''> {totalPrice.toLocaleString('tr-TR')} ₺</span>{' '}
        </h5>
      </div>

      <div className='d-flex align-items-center justify-content-between  mb-4 pb-sm-2'>
        {/* <div className='d-flex align-items-center flex-wrap '>
          <h5 className='h4 me-3 mb-0'>Ödeme Bilgileri:</h5>
        </div> */}
        <div className='d-flex align-items-center ps-lg-3'>
          {cards?.map(({imgSrc, imgAlt}, indx) => (
            <div key={indx} className='d-flex border rounded-1 overflow-hidden shadow-sm me-2'>
              <img src={toAbsoluteUrl(imgSrc)} width={52} height={30} alt={imgAlt} />
            </div>
          ))}
        </div>
      </div>
      <form onSubmit={formik?.handleSubmit}>
        <div style={{width: '300px'}} className='mb-3 text-start fs-5'>
          <label className='form-label text-white' htmlFor='format-card-number'>
            Kredi veya Banka Kartı Numarası
          </label>
          <PatternFormat
            {...formik.getFieldProps('cardNumber')}
            name='cardNumber'
            className='form-control form-control-lg'
            id='format-card-number'
            data-format='card'
            required
            onChange={handleChange}
            format='#### #### #### ####'
            placeholder='16 haneli kredi veya banka kartı numarası'
          />
          {formik.touched.cardNumber && formik.errors.cardNumber && <span className='text-primary fs-sm'>{formik.errors.cardNumber}</span>}
        </div>
        <div style={{width: '300px'}} className='mb-3 text-start fs-5'>
          <label className='form-label text-white' htmlFor='format-card-number'>
            Kart Üzerindeki İsim
          </label>
          <input {...formik.getFieldProps('cardHolder')} name='cardHolder' className='form-control form-control-lg' type='text' required id='format-card-number' data-format='card' placeholder='Kartta yazıldığı gibi' />
          {formik.touched.cardHolder && formik.errors.cardHolder && <span className='text-primary fs-sm'>{formik.errors.cardHolder}</span>}
        </div>
        <div style={{width: '300px'}} className='mb-3 text-start fs-5'>
          <label className='form-label text-white' htmlFor='format-date'>
            Son Kullanma Tarihi
          </label>
          <PatternFormat {...formik.getFieldProps('cardExpirationDate')} name='cardExpirationDate' className='form-control form-control-lg' required id='format-date' format='##/##' data-format='date' placeholder='AA/YY' />
          {formik.touched.cardExpirationDate && formik.errors.cardExpirationDate && <span className='text-primary fs-sm'>{formik.errors.cardExpirationDate}</span>}
        </div>
        <div style={{width: '300px'}} className='mb-3 text-start fs-5'>
          <label className='form-label text-white' htmlFor='format-card-cvc'>
            CVV
          </label>
          <PatternFormat {...formik.getFieldProps('cardCvc')} name='cardCvc' className='form-control form-control-lg' id='format-card-cvc' required format='###' placeholder='3 digits' />
          {formik.touched.cardCvc && formik.errors.cardCvc && <span className='text-primary fs-sm'>{formik.errors.cardCvc}</span>}
        </div>

        {installments && (
          <div style={{width: '300px'}} className='mb-3 text-start'>
            <label htmlFor='exampleInputSinif' className='form-label text-white fs-5'>
              Taksit Seçenekleri
            </label>
            <select className='form-select form-select-lg mb-3' {...formik.getFieldProps('installmantId')} name='installmantId'>
              <option value={''} disabled>
                Taksit seçeneklerini gör
              </option>
              {installments?.installmentPrices?.map((item, index) => (
                <option key={index} value={item.installmentNumber}>
                  {item?.installmentNumber} taksit ({item?.installmentNumber}x{item?.installmentPrice}₺)
                </option>
              ))}
            </select>
            {formik.touched.installmantId && formik.errors.installmantId && <span className='text-primary fs-sm'>{formik.errors.installmantId}</span>}
            <small className='text-white'>Taksit seçenekleri banka koşullarına göre değişiklik gösterir.</small>
          </div>
        )}

        <div className='position-relative bottom-0 d-flex justify-content-center w-100 pb-5'>
          <div style={{width: '300px'}} className='d-flex justify-content-around '>
            <div onClick={() => setCurrentStep((prev: number) => prev - 1)} className={`btn btn-lg btn-outline-light`}>
              Geri
            </div>
            <button type='submit' className='btn btn-lg btn-primary'>
              Öde
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Payment
